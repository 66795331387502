'use client';

import useWanderUser from '@/features/user/hooks/useWanderUser';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import track from '../actions/track';

const TrackSearch = () => {
  const searchParams = useSearchParams();

  const user = useWanderUser();

  useEffect(() => {
    if (searchParams.has('category')) {
      track({
        event: 'products_list_viewed',
        data: {
          product_category: searchParams.get('category'),
          ...(user?.id && {
            user_id: user.id,
            email: user.email,
          }),
        },
      });
    }

    if (searchParams.has('search')) {
      track({
        event: 'products_searched',
        data: {
          search_query: searchParams.get('search'),
          ...(user?.id && {
            user_id: user.id,
            email: user.email,
          }),
        },
      });
    }
  }, [searchParams, user?.id, user?.email]);

  return null;
};

export default TrackSearch;
